import { FeatureToggleSDK } from '@indriver/hydra';
import getConfig from 'next/config';
import { getUrlWithNamespaceHash } from 'common/lib/get-url-with-namespace-hash';

// TODO: Переменную в serverRuntimeConfig
const { publicRuntimeConfig } = getConfig();

const sdk = new FeatureToggleSDK(
    publicRuntimeConfig?.ENABLE_DEV
        ? getUrlWithNamespaceHash(publicRuntimeConfig?.AB_SDK_HOST)
        : publicRuntimeConfig?.AB_SDK_HOST ?? '',
    { timeout: 3000 },
);

export enum Experiments {
    INTERCITY3_WEB_POPULAR_ROUTES = 'intercity3_web_popular_routes',
    INTERCITY3_WEB_NEW_ADDRESS_FIELDS = 'intercity3_web_new_address_fields',
    INTERCITY3_WEB_CPF_FORM = 'intercity3_web_cpf_form',
    INTERCITY3_WEB_REDESIGN_MAIN = 'intercity3_web_redesign_main',
    INTERCITY3_WEB_REDESIGN_FORM = 'intercity3_web_redesign_form',
    INTERCITY3_WEB_CHAT = 'intercity3_web_chat',
    INTERCITY3_WEB_TOLLS = 'intercity3_web_tolls',
    INTERCITY3_WEB_REDO_CONTACT_ICONS = 'intercity3_web_redo_contact_icons',
    BLOOMREACH_CONFIGURATION = 'bloomreach_configuration',
}

// 1. order_page - если true показываем плашку на странице с бидами и принятым заказом.
// 2. after_click_find - если true то показывается модалка после клика на find driver
// 3. before_click_find - если true показываем после того как выбрали А и Б точки

export enum BloomreachExperiments {
    first_time = 'send_first_time_marketing_event',
    registration = 'send_registration_marketing_event',
    order_created = 'send_order_created_marketing_event',
    bid_accepted = 'send_bid_accepted_marketing_event',
    order_cancelled = 'send_order_cancelled_marketing_event',
    ride_finished = 'send_ride_finished_marketing_event',
    set_point_to = 'send_set_point_to_marketing_event',
    ride_cancelled_by_customer = 'send_ride_cancelled_by_customer_marketing_event',
}
interface IToggleVariations {
    [key: string]: boolean | string | number;
}

export interface ITogglesInfo {
    isLoading: boolean;
}

export interface IBloomreachConfiguration {
    url: string;
    apiKey: string;
    projectToken: string;
}

export type IAllExperimentsList = Experiments & BloomreachExperiments;

export interface ISerializedToggles extends ITogglesInfo {
    experiments: {
        [key: string | IAllExperimentsList]: IToggleVariations;
    };
}

export const TOGGLES_INITIAL: ISerializedToggles = { experiments: {}, isLoading: true };

interface ITogglesParams {
    gaId?: string;
    countryId?: number;
    userId?: string | number;
    namespaces?: string[];
}

export const getSerializeToggles: (params: ITogglesParams) => Promise<ISerializedToggles> = async ({
    gaId = '',
    countryId = undefined,
    userId = '',
    namespaces = ['app_start', 'intercity3'],
}) => {
    const res = await sdk.getToggles({
        namespace: namespaces.toString(),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userId,
        device_id: gaId,
        country_id: countryId,
        os: 'web',
    });

    return res
        ? res.reduce<ISerializedToggles>((acc, item) => {
              acc.experiments[item.name] = {};
              item.variations.forEach((variation) => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  acc.experiments[item.name][variation.key] = variation.value;
              });
              return acc;
          }, TOGGLES_INITIAL)
        : TOGGLES_INITIAL;
};
