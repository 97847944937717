import { logger } from '@indriver/elastic-logger';
import * as Sentry from '@sentry/nextjs';

export type EventValue = Record<string, string | number | boolean | Date | null | undefined | string[] | number[]>;

interface IEventPayload {
    event?: string;
    // eventValue?: EventValue;
}

export const sendAnalyticsEvent = (event: string, eventValue?: EventValue): void => {
    // if (process.env.NODE_ENV === 'development') {
    //     return;
    // }

    try {
        let payload: IEventPayload & EventValue = {
            event,
            // eventValue: {
            //     device: isMobile() ? 'mobile' : 'desktop',
            // },
        };

        if (eventValue) {
            payload = {
                ...payload,
                ...eventValue,
            };
        }

        window.dataLayer.push(payload);
        logger.info('GTM_EVENT', JSON.stringify(payload, null, 2));
    } catch (error) {
        Sentry.captureException(error);
        logger.error('GTM_EVENT_ERROR', error);
    }
};

export const sendBloomreachAnalyticsEvent = async (
    event: string,
    eventValue?: EventValue,
    frequency: 'once' | 'session' | 'always' = 'always',
): Promise<void> => {
    try {
        // Объекты для отслеживания отправленных событий в localStorage и sessionStorage
        const sentEventsLocalStorage: Record<string, boolean> = JSON.parse(localStorage.getItem('sent_events') || '{}');
        const sentEventsSessionStorage: Record<string, boolean> = JSON.parse(
            sessionStorage.getItem('sent_events') || '{}',
        );

        if (
            (frequency === 'once' && sentEventsLocalStorage[event]) ||
            (frequency === 'session' && sentEventsSessionStorage[event])
        ) {
            return; // Если событие уже отправлено, прерываем выполнение функции
        }

        let payload: IEventPayload & EventValue = {};

        if (eventValue) {
            payload = {
                ...payload,
                ...eventValue,
            };
        }

        await window.exponea.track(
            event,
            payload,
            () => logger.info('BLOOMREACH_EVENT', JSON.stringify({ event, payload }, null, 2)),
            () => logger.error('BLOOMREACH_EVENT_ERROR_SEND'),
        );

        // Помечаем событие как отправленное
        if (frequency === 'once') {
            sentEventsLocalStorage[event] = true;
            localStorage.setItem('sent_events', JSON.stringify(sentEventsLocalStorage));
        }
        if (frequency === 'session') {
            sentEventsSessionStorage[event] = true;
            sessionStorage.setItem('sent_events', JSON.stringify(sentEventsSessionStorage));
        }
    } catch (error) {
        Sentry.captureException(error);

        logger.error('BLOOMREACH_EVENT_ERROR', error);
    }
};
